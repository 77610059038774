import { Config } from '@gimlite/watermelon/config';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { CustomRouter } from './CustomRouter';
import config from './config';
import { addParkingInControlUserService } from './wizards/addParkingInControlUser/addParkingInControlUser.machine';
import { AddParkingInControlUserWizard } from './wizards/addParkingInControlUser/addParkingInControlUser.wizard';
import { createAdminUsersService } from './wizards/createAdminUser/createAdminUser.machine';
import { CreateAdminUserWizard } from './wizards/createAdminUser/createAdminUser.wizard';
import { createCameraService } from './wizards/createCamera/createCamera.machine';
import { CreateCameraWizard } from './wizards/createCamera/createCamera.wizard';
import { createClientService } from './wizards/createClient/createClient.machine';
import { CreateClientWizard } from './wizards/createClient/createClient.wizard';
import { createControlUsersService } from './wizards/createControlUser/createControlUser.machine';
import { CreateControlUserWizard } from './wizards/createControlUser/createControlUser.wizard';
import { createOperatorService } from './wizards/createOperator/createOperator.machine';
import { CreateOperatorWizard } from './wizards/createOperator/createOperator.wizard';
import { createPmsTechnicalUsersService } from './wizards/createPmsTechnicalUser/createPmsTechnicalUser.machine';
import { CreatePmsTechnicalUserWizard } from './wizards/createPmsTechnicalUser/createPmsTechnicalUser.wizard';
import { createPrestoScanService } from './wizards/createPrestoScan/createPrestoScan.machine';
import { CreatePrestoScanWizard } from './wizards/createPrestoScan/createPrestoScan.wizard';
import { createSiteService } from './wizards/createSite/createSite.machine';
import { CreateSiteWizard } from './wizards/createSite/createSite.wizard';
import { UpdateAdminUserWizard } from './wizards/updateAdminUser/updateAdminUser.wizard';
import { updateCameraService } from './wizards/updateCamera/updateCamera.machine';
import { UpdateCameraWizard } from './wizards/updateCamera/updateCamera.wizard';
import { updateControlUserService } from './wizards/updateControlUser/updateControlUser.machine';
import { UpdateControlUserWizard } from './wizards/updateControlUser/updateControlUser.wizard';
import { updateOperatorService } from './wizards/updateOperator/updateOperator.machine';
import { UpdateOperatorWizard } from './wizards/updateOperator/updateOperator.wizard';
import { updateSiteService } from './wizards/updateSite/updateSite.machine';
import { UpdateSiteWizard } from './wizards/updateSite/updateSite.wizard';

const root = createRoot(document.getElementById('root') as HTMLElement);
const { ENV, VERSION_FRONT, BFF_HOST, BFF_WS_HOST } = config;

root.render(
  <React.StrictMode>
    <Config
      debug={false}
      version={{
        env: config?.ENV,
        front: VERSION_FRONT,
      }}
      server={{
        bff: BFF_HOST,
        // ws: BFF_WS_HOST,
      }}
      account={{
        updatePassword: true,
      }}
      isResponsive={false}
      authorization={config.BFF_TOKEN}
      theme={{
        color: {
          dark: '#5dbed2',
          light: '#0a2652',
        },
        defaultTheme: 'dark',
      }}
      wizards={[
        {
          id: 'createSite',
          machine: createSiteService,
          component: CreateSiteWizard,
        },
        {
          id: 'updateSite',
          machine: updateSiteService,
          component: UpdateSiteWizard,
        },
        {
          id: 'createClient',
          machine: createClientService,
          component: CreateClientWizard,
        },
        {
          id: 'createOperator',
          machine: createOperatorService,
          component: CreateOperatorWizard,
        },
        {
          id: 'updateOperator',
          machine: updateOperatorService,
          component: UpdateOperatorWizard,
        },
        {
          id: 'createControlUser',
          machine: createControlUsersService,
          component: CreateControlUserWizard,
        },
        {
          id: 'createPmsTechnicalUser',
          machine: createPmsTechnicalUsersService,
          component: CreatePmsTechnicalUserWizard,
        },
        {
          id: 'createAdminUser',
          machine: createAdminUsersService,
          component: CreateAdminUserWizard,
        },
        {
          id: 'updateAdminUser',
          machine: createAdminUsersService,
          component: UpdateAdminUserWizard,
        },
        {
          id: 'createPrestoScan',
          machine: createPrestoScanService,
          component: CreatePrestoScanWizard,
        },
        {
          id: 'createCamera',
          machine: createCameraService,
          component: CreateCameraWizard,
        },
        {
          id: 'updateCamera',
          machine: updateCameraService,
          component: UpdateCameraWizard,
        },
        {
          id: 'updateControlUser',
          machine: updateControlUserService,
          component: UpdateControlUserWizard,
        },
        {
          id: 'addParkingInControlUser',
          machine: addParkingInControlUserService,
          component: AddParkingInControlUserWizard,
        },
      ]}
    >
      <CustomRouter />
    </Config>
  </React.StrictMode>,
);
