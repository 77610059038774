import { Badge } from '@gimlite/watermelon/components/badge/badge.component';
import { DateTime, Duration } from 'luxon';

export const searchRender = ({ list, paging }) => {
  return {
    list: list.map(
      ({
        amount,
        currency,
        startDate,
        endDate,
        vehicle,
        state,
        error,
        ...rest
      }) => {
        const minutes = Math.floor(
          DateTime.fromISO(endDate)
            .diff(DateTime.fromISO(startDate))
            .as('minutes'),
        );

        const calculatedDuration =
          minutes >= 60
            ? Duration.fromObject({ minutes }).rescale().toHuman({
                unitDisplay: 'short',
              })
            : Duration.fromObject({ minutes }).toHuman({
                unitDisplay: 'short',
              });

        return {
          ...rest,
          startDate: new Date(startDate).toLocaleString(DateTime.DATE_SHORT),
          endDate: `${new Date(endDate).toLocaleString(DateTime.DATE_SHORT)}`,
          calculatedDuration,
          errorCode: error?.code || '',
          errorMessage: error?.message || '',
          plate: vehicle.lpn,
          // TODO: faire un composant avec ça
          amount: new Intl.NumberFormat(currency, {
            style: 'currency',
            currency: currency,
          }).format(amount / 100),
          state:
            state !== 'DONE' ? (
              <Badge config={{ color: 'error', text: state }} />
            ) : (
              <Badge config={{ color: 'success', text: state }} />
            ),
        };
      },
    ),
    paging,
  };
};

export const read = (context, element) => {
  return element;
};
