import { MinusCircleOutlined } from '@ant-design/icons';
import { Checkbox } from '@gimlite/watermelon/components/checkbox/checkbox.component';
import { Form } from '@gimlite/watermelon/components/form/form.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { Row } from '@gimlite/watermelon/components/row/row.component';
import { Space } from '@gimlite/watermelon/components/space/space.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Write } from '@gimlite/watermelon/components/write/write.component';
import {
  CreatePrestoScanWizardContext,
  spaceBeetwenInput,
  spaceBeetwenTitle,
} from '../createPrestoScan.wizard';
import './general.scss';
import { Button, Col } from '@gimlite/watermelon';

export const VaoStep = ({
  context: { createVAO, searchParking },
  validate,
  submit,
}: WizardItemType.Config.Component<CreatePrestoScanWizardContext>) => {
  console.log('🚀 ~ createVAO:', createVAO);

  return (
    <Form
      data={{ value: createVAO }}
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value) => {
          submit({ createVAO: value });
        },
        validate: (value) => {
          validate(value);
        },
      }}
    >
      <Form.Structure config={{ layout: 'column', width: 'full', fill: true }}>
        <Write
          data={{ item: 'Configuration des différents ups' }}
          config={{
            mode: 'title-small',
          }}
        ></Write>

        <Row>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'sitesLegacyId',
              label: 'upsId utilisé pour le monitoring',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                type: { name: 'text' },
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
        </Row>
        <Col>
          <Form.List
            data={{
              defaultValues: [],
            }}
            config={{
              name: 'rightsLegacyIds',
            }}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ name, key, ...restOfField }: any, index) => (
                  <div style={{ width: '100%' }} key={key}>
                    {index !== 0 && (
                      <Space config={{ count: 2, way: 'vertical' }}></Space>
                    )}

                    <Row
                      config={{
                        vertical: 'end',
                        horizontal: 'end',
                        width: 'full',
                      }}
                    >
                      <Form.Item
                        {...restOfField}
                        config={{
                          way: 'vertical',
                          label: "ID d'un UPS utilisé pour les tickets",
                          name: [name, 'id'],
                          rules: [{ required: true }],
                        }}
                      >
                        <Input
                          config={{
                            height: 'xlarge',
                            width: 'full',
                          }}
                        />
                      </Form.Item>

                      <>
                        <Space
                          config={{
                            count: spaceBeetwenInput,
                            way: 'horizontal',
                          }}
                        ></Space>
                        <div className="upsTranslateDeleteContain">
                          <MinusCircleOutlined
                            style={{ fontSize: '25px' }}
                            onClick={() => remove(name)}
                          />
                        </div>
                      </>
                    </Row>
                  </div>
                ))}
                <Space config={{ count: 2, way: 'vertical' }}></Space>

                <Row config={{ horizontal: 'center' }}>
                  <Button
                    handleEvent={{
                      click: () => add(),
                    }}
                    config={{
                      text: "Ajouter un ID d'UPS utilisé pour les tickets",
                    }}
                  />
                </Row>
              </>
            )}
          </Form.List>
        </Col>
        <Write
          data={{ item: 'Périodes de tolérance' }}
          config={{
            mode: 'title-small',
          }}
        ></Write>

        <Row>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'entryTolerancePeriod',
              label: 'Période tolérance de début (en minutes)',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                type: { name: 'number' },
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
          <Space
            config={{ count: spaceBeetwenInput, way: 'horizontal' }}
          ></Space>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'exitTolerancePeriod',
              label: 'Période tolérance de fin (en minutes)',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                type: { name: 'number' },
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
        </Row>
        <Space config={{ count: spaceBeetwenTitle, way: 'vertical' }}></Space>
        <Write
          data={{ item: 'Durée de rétentions' }}
          config={{
            mode: 'title-small',
          }}
        ></Write>

        <Row>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'deletionDelay_incomplete',
              label: 'Incomplète (en jours)',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                type: { name: 'number' },
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
          <Space
            config={{ count: spaceBeetwenInput, way: 'horizontal' }}
          ></Space>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'deletionDelay_complete',
              label: 'En règle (en jours)',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                type: { name: 'number' },
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
          <Space
            config={{ count: spaceBeetwenInput, way: 'horizontal' }}
          ></Space>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'deletionDelay_toCheck',
              label: 'À contrôler (en jours)',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                type: { name: 'number' },
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
        </Row>
        <Row>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'deletionDelay_unfined',
              label: 'Non verbalisée (en jours)',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                type: { name: 'number' },
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
          <Space
            config={{ count: spaceBeetwenInput, way: 'horizontal' }}
          ></Space>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'deletionDelay_fined',
              label: 'Verbalisée (en jours)',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                type: { name: 'number' },
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
        </Row>
        <Space config={{ count: spaceBeetwenTitle, way: 'vertical' }}></Space>
        <Write
          data={{ item: 'Pallier de défaut de droit' }}
          config={{
            mode: 'title-small',
          }}
        ></Write>
        <Row>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'outrageTreshold_warning',
              label: 'Orange (en minutes)',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                type: { name: 'number' },
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
          <Space
            config={{ count: spaceBeetwenInput, way: 'horizontal' }}
          ></Space>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'outrageTreshold_danger',
              label: 'Rouge (en minutes)',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                type: { name: 'number' },
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
        </Row>
        <Space config={{ count: spaceBeetwenTitle, way: 'vertical' }}></Space>
        <Write
          data={{ item: 'Fonctionnalités' }}
          config={{
            mode: 'title-small',
          }}
        ></Write>

        <Row>
          <Form.Item
            config={{
              name: 'isVerbalizationAllowed',
              label: 'Droit de verbaliser',
            }}
          >
            <Checkbox
              config={{
                size: 'medium',
              }}
            />
          </Form.Item>
        </Row>
        <Row>
          <Form.Item
            config={{
              name: 'showOccupancy',
              label: "Visualization de l'occupation",
            }}
          >
            <Checkbox
              config={{
                size: 'medium',
              }}
            />
          </Form.Item>
        </Row>
      </Form.Structure>
    </Form>
  );
};
