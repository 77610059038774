import { DocumentNode } from 'graphql'
import { useEffect, useMemo, useState } from 'react'
import { requestGQL } from '../functions/request.function'
import { IDENTITY } from '../machines/machine-factory'
import { useMyUrl } from './useMyUrl.hook'
import { useRootStore } from './useRootStore.hook'

export type SearchOfParams = {
  gql: DocumentNode
  limit?: number
  wsSubscriptions?: string[]
  searchParams?: any
  render?: (res: any) => any
  queryBuilder?: () => object
}

const DEFAULT_QUERY_BUILDER = (id: string) => ({ id })

export const useSearchOf = <TEntity, TPaging extends Record<string, number>>({
  gql,
  limit = 15,
  wsSubscriptions,
  searchParams,
  render = IDENTITY,
  queryBuilder = () => ({})
}: SearchOfParams) => {
  const { GlobalStore } = useRootStore()

  // const [pagingIn, setPagingIn] = useState<{ page: number limit: number }>({
  //   page: 1,
  //   limit: limit
  // })

  const [pagingOut, setPagingOut] = useState<{
    limit: number
    current: number
    count: number
  }>()

  const { getParamsUrl, getParamsUrlString, setParamsUrl } = useMyUrl({})

  const getParamsUrlMemo = useMemo(() => getParamsUrl, [getParamsUrl])
  const [list, setList] = useState<TEntity[]>()

  const setSearch = (data: {
    paging: TPaging
    params?: Record<string, unknown>
  }) => {
    if (Object.keys(data.paging).length === 0) return

    requestGQL({
      params: { ...data.paging, ...data.params, ...searchParams, ...queryBuilder() },
      gql,
      render,
    }).then((data) => {
      if (!data) return
      setList(data.list)
      setPagingOut(data.paging)
    })
  }

  const pagingProcess = () => {
    setSearch({
      paging: getParamsUrl,
      params: getParamsUrl,
    })
  }

  useEffect(() => {
    wsSubscriptions?.forEach((subscription) => {
      GlobalStore.socket?.on(subscription, pagingProcess)
    })

    return () => {
      wsSubscriptions?.forEach((subscription) => {
        GlobalStore.socket?.off(subscription, pagingProcess)
      })
    }
  }, [GlobalStore.socket?.connected, getParamsUrl])

  return { list, paging: pagingOut, setSearch }
}
